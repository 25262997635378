/* Main */
.bg-primary,
.bg-none,
.bg-primary-subtle,
.bg-opacity-10 {
  transition: background-color 0.3s ease-in-out;
}

section {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
}

.navbar-toggler {
  color: #90762C!important;
  border-color: #90762C!important;
}

.inside-bottom-shadow {
  position: relative;
}

.inside-bottom-shadow::after {
  content: "";
  position: absolute;
  inset: 0;
  box-shadow: inset 0 -10px 10px -10px white;
}

.anchor { /* Used for <a> elements that link to specific part of page */
  display: block;
  height: 80px; /*same height as header*/
  margin-top: -80px; /*same height as header*/
  visibility: hidden;
}

/* Colors */

.hemson-primary {
  color: rgb(157, 34, 53)!important; /* #9D2235 */
}

.hemson-secondary {
  color: rgb(144, 118, 44)!important; /* #90762C */
}

.hemson-tertiary {
  color: rgb(0, 76, 58)!important; /* #004C3A */
}

/* MEDIA QUERIES */

/* Used for the dashboard feature image */
.dashboard-feature-margin-bottom {
  margin-bottom: -60px;
}

@media (min-width: 576px) {
  .dashboard-feature-margin-bottom {
    margin-bottom: -75px;
  }
}

@media (min-width: 768px) {
  .dashboard-feature-margin-bottom {
    margin-bottom: -150px;
  }
}

@media (min-width: 992px) {

}

@media (min-width: 1200px) {

}

@media (min-width: 1400px) {

}